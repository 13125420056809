.class-button {
  height: 18px;
  text-align: center;
  background-color: #007bff;
  color: #fff;
  padding: 10px 50px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: large;
  margin-top: 26px;
}

.class-button:hover {
  background-color: #0056b3;
}

.class-button:active{
  transform: scale(0.98); 
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24); 
}