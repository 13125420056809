.text-box {
  display: flex;
  flex-direction: column;
  width: 250px;
  gap: 5px;
}
.text-box label {
  padding-left: 2px;
  font-size: 18px;
  font-weight: bold;
}

.text-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background-color: #444;
  color: #fff;
  border: none;
  border-radius: 5px;
}
