.card {
    background-color: #555; 
    color: #fff; 
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    padding: 20px;
    margin: 10px;
    transition: transform 0.2s;
    cursor: pointer;
}

.card:hover {
    background-color: #666; 
    transform: scale(1.005); 
}

.card:active{
    transform: scale(0.98); 
    /* Scaling button to 0.98 to its original size */ 
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24); 
    /* Lowering the shadow */ 
}

.card-content {
    padding: 10px;
}

.card-title {
    font-size: 24px;
    margin: 0;
    padding: 0;
}

.card-rating {
    font-size: 16px;
    margin: 5px 0;
}

.card-type {
    font-size: 16px;
    margin: 5px 0;
}
